var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),(_vm.filteredData!=null && !_vm.api.isLoading)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.searchData,"items":_vm.filteredData,"headers":_vm.dataHeader,"dense":""},scopedSlots:_vm._u([{key:"item.Index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.filteredData.indexOf(item)+1)+" ")]}},{key:"item.last_login",fn:function(ref){
var item = ref.item;
return [(item.last_login!=null)?_c('span',[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.last_login))+" ")]):_vm._e()]}},{key:"item.invoice_created_at",fn:function(ref){
var item = ref.item;
return [(item.invoice_created_at!=null)?_c('span',[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.invoice_created_at))+" ")]):_vm._e()]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'PageCompaniesDetail',params:{'id':item.company_id}}}},[_vm._v(" "+_vm._s(item.company_name)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"auto"}},[_c('v-row',{staticClass:"pa-4",attrs:{"no-gutters":""}},[_c('v-col',{},[_c('v-row',[_c('v-toolbar-title',{staticClass:"font-weight-bold text-h4 my-3"},[_vm._v(" Last Login ")])],1),_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"0px","padding-bottom":"0px","padding-left":"4px","padding-right":"11px"},attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"my-1",staticStyle:{"margin-right":"190px"},attrs:{"cols":"3"}},[_c('small',{staticClass:"ml-2 font-weight-bold"},[_vm._v("Subscription :")])]),_c('v-col',{staticClass:"my-1",staticStyle:{"padding-left":"0px","padding-right":"0px"},attrs:{"cols":"5"}},[_c('small',{staticClass:"ml-2 font-weight-bold"},[_vm._v("Last Login :")])])],1),_c('div',{staticClass:"d-flex flex-row justify-start ml-2"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-text-field',{staticStyle:{"border-radius":"4px 0px 0px 4px"},attrs:{"dense":"","outlined":"","clearable":"","prepend-inner-icon":"mdi-calendar","label":"Start Date"},on:{"click:prepend-inner":function (){
                                            _vm.isSubscriptionStartDate = true;
                                        },"click":function (){
                                            _vm.isSubscriptionStartDate =true;
                                        }},model:{value:(_vm.subscriptionStartDate),callback:function ($$v) {_vm.subscriptionStartDate=$$v},expression:"subscriptionStartDate"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isSubscriptionStartDate),callback:function ($$v) {_vm.isSubscriptionStartDate=$$v},expression:"isSubscriptionStartDate"}},[_c('v-card',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',[_vm._v(" Subscription Start Date ")]),_c('div',[_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.subscriptionStartDate),callback:function ($$v) {_vm.subscriptionStartDate=$$v},expression:"subscriptionStartDate"}}),_vm._t("footer",function(){return [_c('div',{staticClass:"d-flex justify-end white"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                                    _vm.isSubscriptionStartDate=false;
                                                                    _vm.subscriptionStartDate=null;
                                                                }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                                    _vm.isSubscriptionStartDate = false;
                                                                }}},[_vm._v(" Confirm ")])],1)]})],2)])])],1),_c('div',[_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"dense":"","outlined":"","clearable":"","prepend-inner-icon":"mdi-calendar","label":"End Date"},on:{"click:prepend-inner":function (){
                                                _vm.isSubscriptionEndDate = true;
                                            },"click":function (){
                                                _vm.isSubscriptionEndDate = true;
                                            }},model:{value:(_vm.subscriptionEndDate),callback:function ($$v) {_vm.subscriptionEndDate=$$v},expression:"subscriptionEndDate"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isSubscriptionEndDate),callback:function ($$v) {_vm.isSubscriptionEndDate=$$v},expression:"isSubscriptionEndDate"}},[_c('v-card',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',[_vm._v(" Subscription End Date ")]),_c('div',[_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.subscriptionEndDate),callback:function ($$v) {_vm.subscriptionEndDate=$$v},expression:"subscriptionEndDate"}})],1)]),_c('div',{attrs:{"name":"footer"}},[_c('div',{staticClass:"d-flex justify-end my-3"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                            _vm.isSubscriptionEndDate = false;
                                                            _vm.subscriptionEndDate=null;
                                                        }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                            _vm.isSubscriptionEndDate = false;
                                                        }}},[_vm._v(" Confirm ")])],1)])])],1)],1)],1),_c('div',{staticClass:"d-flex flex-row"},[_c('v-text-field',{staticStyle:{"border-radius":"0px 0px 0px 0px"},attrs:{"dense":"","outlined":"","clearable":"","prepend-inner-icon":"mdi-calendar","label":"Start Date"},on:{"click:prepend-inner":function (){
                                            _vm.isLastLoginStartDate = true;
                                        },"click":function (){
                                            _vm.isLastLoginStartDate =true;
                                        }},model:{value:(_vm.lastLoginStartDate),callback:function ($$v) {_vm.lastLoginStartDate=$$v},expression:"lastLoginStartDate"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isLastLoginStartDate),callback:function ($$v) {_vm.isLastLoginStartDate=$$v},expression:"isLastLoginStartDate"}},[_c('v-card',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',[_vm._v(" Last Login Start Date ")]),_c('div',[_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.lastLoginStartDate),callback:function ($$v) {_vm.lastLoginStartDate=$$v},expression:"lastLoginStartDate"}}),_vm._t("footer",function(){return [_c('div',{staticClass:"d-flex justify-end white"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                                    _vm.isLastLoginStartDate=false;
                                                                    _vm.lastLoginStartDate=null;
                                                                }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                                    _vm.isLastLoginStartDate = false;
                                                                }}},[_vm._v(" Confirm ")])],1)]})],2)])])],1),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"dense":"","outlined":"","clearable":"","prepend-inner-icon":"mdi-calendar","label":"End Date"},on:{"click:prepend-inner":function (){
                                            _vm.isLastLoginEndDate = true;
                                        },"click":function (){
                                            _vm.isLastLoginEndDate = true;
                                        }},model:{value:(_vm.lastLoginEndDate),callback:function ($$v) {_vm.lastLoginEndDate=$$v},expression:"lastLoginEndDate"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isLastLoginEndDate),callback:function ($$v) {_vm.isLastLoginEndDate=$$v},expression:"isLastLoginEndDate"}},[_c('v-card',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',[_vm._v(" Last Login End Date ")]),_c('div',[_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.lastLoginEndDate),callback:function ($$v) {_vm.lastLoginEndDate=$$v},expression:"lastLoginEndDate"}})],1)]),_c('div',{attrs:{"name":"footer"}},[_c('div',{staticClass:"d-flex justify-end my-3"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                        _vm.isLastLoginEndDate = false;
                                                        _vm.lastLoginEndDate = null;
                                                    }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                        _vm.isLastLoginEndDate = false;
                                                    }}},[_vm._v(" Confirm ")])],1)])])],1),_c('v-btn',{staticStyle:{"border-radius":"0px 4px 4px 0px","height":"40px"},attrs:{"color":"primary"},on:{"click":_vm.filterDate}},[_vm._v(" Filter ")])],1)])],1)],1),_c('v-row',{},[_c('v-col',{staticStyle:{"padding-top":"0px"}},[_c('v-autocomplete',{attrs:{"items":_vm.filterVerificationOptions,"label":"Status","return-object":"","dense":"","outlined":""},on:{"change":_vm.filterDate},model:{value:(_vm.filteredVerificationData),callback:function ($$v) {_vm.filteredVerificationData=$$v},expression:"filteredVerificationData"}})],1),_c('v-col',{staticStyle:{"padding-top":"0px"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Search"},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}})],1)],1),_c('v-row'),_c('div',{staticClass:"d-flex justify-end my-6"},[_c('v-btn',{staticClass:"mx-3",attrs:{"color":"primary ","outlined":""},on:{"click":_vm.resetFilter}},[_vm._v(" Reset Filter ")]),_c('JsonCSV',{attrs:{"data":_vm.downloadableData}},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Download ")])],1)],1)],1)],1)],1)]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }