<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table :search="searchData" :items="filteredData" :headers="dataHeader" v-if="filteredData!=null && !api.isLoading" dense class="elevation-1">

            <template v-slot:item.Index="{item}">
                {{ filteredData.indexOf(item)+1 }}
            </template>
            <template v-slot:item.last_login="{item}">
                <span v-if="item.last_login!=null">
                    {{ convertTimeZone(item.last_login) }}
                </span>
            </template>
            <template v-slot:item.invoice_created_at="{item}">
                <span v-if="item.invoice_created_at!=null">
                    {{ convertTimeZone(item.invoice_created_at) }}
                </span>
            </template>
            <template v-slot:item.company_name="{item}">
                <router-link :to="{name:'PageCompaniesDetail',params:{'id':item.company_id}}">
                    {{ item.company_name }}
                </router-link>
            </template>

            
            <!-- <template v-slot:item.data="{item}">
                {{ JSON.stringify(item.data) }}
            </template> -->
            <template v-slot:top>
                <v-toolbar flat height="auto">
                    <v-row no-gutters class="pa-4">  
                        <v-col class="">
                            <v-row>
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Last Login
                                </v-toolbar-title>
                            </v-row>


                            <v-row >
                                <v-col cols="12" style="padding-top:0px;padding-bottom:0px;padding-left:4px;padding-right:11px">
                                    <v-row>
                                        <v-col class="my-1" cols="3" style="margin-right:190px">
                                            <small class="ml-2 font-weight-bold">Subscription :</small>

                                        </v-col>

                                        <v-col class="my-1" cols="5" style="padding-left:0px;padding-right:0px">
                                            <small class="ml-2 font-weight-bold">Last Login :</small>

                                        </v-col>
                                        <!-- <v-col cols="2">

                                        </v-col> -->
                                    </v-row>
                                    <div class="d-flex flex-row justify-start ml-2">
                                        <div class="d-flex flex-row">

                                            <v-text-field style="border-radius:4px 0px 0px 4px" dense outlined clearable
                                            prepend-inner-icon="mdi-calendar"
                                            @click:prepend-inner="()=>{
                                                isSubscriptionStartDate = true;
                                            }"
                                            @click="()=>{
                                                isSubscriptionStartDate =true;
                                            }"
                                            label="Start Date"
                                            v-model = "subscriptionStartDate">
                
                                            </v-text-field>
                
                                            <v-dialog v-model="isSubscriptionStartDate" height="auto" width="auto">
                                                <v-card class="pa-3">
                                                    <div class="d-flex flex-column">
                                                        <p>
                                                            Subscription Start Date
                                                        </p>
                                                        <div>
    
                                                            <v-date-picker flat v-model="subscriptionStartDate" :landscape="true"
                                                            :reactive="true">
                                                            </v-date-picker>
    
                                                            <slot name="footer">
                                                                <div class="d-flex justify-end white" >
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isSubscriptionStartDate=false;
                                                                        subscriptionStartDate=null;
                                                                    }">
                                                                        Cancel
    
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isSubscriptionStartDate = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </slot>
                                                        </div>
                                                    </div>
                                                </v-card>
                                                
                                            
                                            </v-dialog>
    
                                            <div>
                                                <v-text-field dense outlined style="border-radius:0px" clearable
                                                prepend-inner-icon="mdi-calendar"
                                                @click:prepend-inner="()=>{
                                                    isSubscriptionEndDate = true;
                                                }"
                                                @click="()=>{
                                                    isSubscriptionEndDate = true;
                                                }"
                                                label="End Date"
                                                v-model = "subscriptionEndDate">
                    
                                                </v-text-field>
                                                
                                                <v-dialog v-model="isSubscriptionEndDate" height="auto" width="auto">
                                                    <v-card class="pa-3">
                                                    <div class="d-flex flex-column">
                                                        <p>
                                                            Subscription End Date
                                                        </p>
                                                        <div>
                                                            <v-date-picker flat v-model="subscriptionEndDate" :landscape="true"
                                                            :reactive="true">
                                                            </v-date-picker>
                                                        </div>
                                                    </div>
                                                    <div name="footer">
                                                        <div class="d-flex justify-end my-3">
                                                            <v-btn color="primary" plain @click="()=>{
                                                                isSubscriptionEndDate = false;
                                                                subscriptionEndDate=null;
                                                            }">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn color="primary" @click="()=>{
                                                                isSubscriptionEndDate = false;
                                                            }">
                                                                Confirm
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </v-card>
                                                    
                                                </v-dialog>
                                            </div>

                                        </div>
                                        <div class="d-flex flex-row">
                                            <v-text-field style="border-radius:0px 0px 0px 0px" dense outlined clearable 
                                            prepend-inner-icon="mdi-calendar"
                                            @click:prepend-inner="()=>{
                                                isLastLoginStartDate = true;
                                            }"
                                            @click="()=>{
                                                isLastLoginStartDate =true;
                                            }"
                                            label="Start Date"
                                            v-model = "lastLoginStartDate">
                
                                            </v-text-field>
                
                                            <v-dialog v-model="isLastLoginStartDate" height="auto" width="auto">
                                                <v-card class="pa-3">
                                                    <div class="d-flex flex-column">
                                                        <p>
                                                            Last Login Start Date
                                                        </p>
                                                        <div>

                                                            <v-date-picker flat v-model="lastLoginStartDate" :landscape="true"
                                                            :reactive="true">
                                                            </v-date-picker>

                                                            <slot name="footer">
                                                                <div class="d-flex justify-end white" >
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isLastLoginStartDate=false;
                                                                        lastLoginStartDate=null;
                                                                    }">
                                                                        Cancel

                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isLastLoginStartDate = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </slot>
                                                        </div>
                                                    </div>
                                                </v-card>
                                                
                                            
                                            </v-dialog>

                                            <v-text-field dense outlined style="border-radius:0px" clearable
                                            prepend-inner-icon="mdi-calendar"
                                            @click:prepend-inner="()=>{
                                                isLastLoginEndDate = true;
                                            }"
                                            @click="()=>{
                                                isLastLoginEndDate = true;
                                            }"
                                            label="End Date"
                                            v-model = "lastLoginEndDate">
                
                                            </v-text-field>
                                            
                                            <v-dialog v-model="isLastLoginEndDate" height="auto" width="auto">
                                                <v-card class="pa-3">
                                                <div class="d-flex flex-column">
                                                    <p>
                                                        Last Login End Date
                                                    </p>
                                                    <div>
                                                        <v-date-picker flat v-model="lastLoginEndDate" :landscape="true"
                                                        :reactive="true">
                                                        </v-date-picker>
                                                    </div>
                                                </div>
                                                <div name="footer">
                                                    <div class="d-flex justify-end my-3">
                                                        <v-btn color="primary" plain @click="()=>{
                                                            isLastLoginEndDate = false;
                                                            lastLoginEndDate = null;
                                                        }">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn color="primary" @click="()=>{
                                                            isLastLoginEndDate = false;
                                                        }">
                                                            Confirm
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-card>
                                                
                                            </v-dialog>

                                            <v-btn color="primary" @click="filterDate" style="border-radius:0px 4px 4px 0px;height:40px">
                                                Filter
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-col>
                                <!-- <v-col>
                                    <div class="ml-2">
                                    
                                       

                                    </div>
                                    <div>
                                      
                                    </div>
                                    <div>

                                       
                                    </div>
                                </v-col> -->
                            </v-row>

                            <v-row class="">
                                <v-col style="padding-top:0px">
                                    <v-autocomplete class="" :items="filterVerificationOptions" 
                                    label="Status"
                                    @change="filterDate"
                                    return-object
                                    v-model="filteredVerificationData" dense outlined >
                                    </v-autocomplete>
                                </v-col>
                                <v-col style="padding-top:0px">
                                    <v-text-field class="" v-model="searchData" dense outlined label="Search">
                                    </v-text-field>
                                </v-col>
                             
                            </v-row>
                            
                           
                            <v-row>
                              
                            </v-row>
                            
                          
                            
                           
                            <!-- <v-row class="my-3 mx-1"> -->
                                <div class="d-flex justify-end my-6">
                                    <v-btn color="primary " class="mx-3" outlined @click="resetFilter">
                                        Reset Filter
                                    </v-btn>
                                    <JsonCSV :data="downloadableData">
                                        <v-btn color="primary">
                                            Download
                                        </v-btn>
                                    </JsonCSV>

                                </div>
                            <!-- </v-row> -->
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>

            <!-- <template v-slot:item.data.company_name="{item}">
                <span> {{ (item.company_name!=null)?item.company_name:"-" }}</span>
            </template> -->
        </v-data-table>

    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
// import JsonCSV from 'vue-json-csv'
export default {
    components:{
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        csvTitle:'filename.csv',
        searchData:null,
        subscriptionStartDate:null,
        subscriptionEndDate:null,
        isSubscriptionStartDate:null,
        isSubscriptionEndDate:null,
        lastLoginStartDate:null,
        lastLoginEndDate:null,
        isLastLoginStartDate:null,
        isLastLoginEndDate:null,
        filteredData:null,
        downloadableData:null,
        filteredVerificationData:"All",
        filteredYearData:null,
        filteredMonthData:null,
        filterVerificationOptions:["All","Active","Expired"],
        filterYearOptions :[2023,2022,2021],
        filterMonthOptions:[
            {
                text:'January',
                value:1,
            },
            {
                text:'February',
                value:2,
            },
            {
                text:'March',
                value:3,
            },
            {
                text:'April',
                value:4,
            },
            {
                text:'May',
                value:5,
            },
            {
                text:'June',
                value:6,
            },
            {
                text:'July',
                value:7,
            },
            {
                text:'August',
                value:8,
            },
            {
                text:'September',
                value:9,
            },
            {
                text:'October',
                value:10,
            },
            {
                text:'November',
                value:11,
            },
            {
                text:'December',
                value:12,
            }
        ],
        filterOptions:["pending","completed","failed","cancelled"],
        fitlerSelected:"failed",
        data:null,
        dataHeader:[
           {
                text:'#',
                value:'Index',
                sortable:false,
           },
           {
                text:'User Name',
                value:'user_name',
           },
           {
                text:'Email',
                value:'email',
           },
           {
                text:'Phone Number',
                value:'mobile',
           },
           {
                text:'Company',
                value:"company_name",
           },
           {
                text:'Status',
                value:'verification',
           },
           {
                text:'Subscription Date',
                value:'invoice_created_at',
           },
           {
                text:'Last Login',
                value:'last_login'
           },
        //    {
        //         text:'Month',
        //         value:'last_login_month'
        //    },
        //    {
        //         text:'Year',
        //         value:'last_login_year',
        //    }
          
        ],
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getUserLastLogin"){
                this.data = resp.data;
                this.filteredData = resp.data;
                
                this.downloadableData = this.filteredData.map(obj=>({
                    "Client":obj.user_name,
                    "Email":obj.email,
                    "mobile":obj.mobile,
                    "Company":obj.company_name,
                    "Subscription Date":this.convertTimeZone(obj.invoice_created_at),
                    "Last Login":this.convertTimeZone(obj.last_login)
                }));
             
                // this.filterCouponBasedOnStatus();
            }
            if(resp.class=="reApplyFailedCoupon"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Re-Apply the coupon"
                location.reload();
                
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchUserLastLoginApi = this.fetchUserLastLogin();

            this.$api.fetch(fetchUserLastLoginApi);
        },
       
        fetchUserLastLogin(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/report/lastlogin";
            return tempApi;
        },
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        reApplyCoupon(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/coupons/failed/reapply";
            return tempApi;

        },
        filterCouponBasedOnStatus(){
            let tempData = this.$_.clone(this.data);
            // let tempFilterOption = this.$_.clone(this.filterOptions);
            let tempFilterSelected = this.$_.clone(this.fitlerSelected);
            let tempDataFiltered = tempData.filter(function(item){
                return item.log_status===tempFilterSelected;
            });
            this.filteredData = tempDataFiltered;
            return 1;


        },
        filterDate(){
            let tempSubscriptionStartDate = this.$_.clone(this.subscriptionStartDate);
            let tempSubscriptionEndDate = this.$_.clone(this.subscriptionEndDate);
            let tempLastLoginStartDate = this.$_.clone(this.lastLoginStartDate);
            let tempLastLoginEndDate = this.$_.clone(this.lastLoginEndDate);
            let tempFilterVerification = this.$_.clone(this.filteredVerificationData);


            let tempData = this.$_.clone(this.data)



            if(tempFilterVerification!=null && tempFilterVerification!=="All"){
                tempData = tempData.filter(function(item){
                    return item.verification === tempFilterVerification;
                })
            }

            if(tempSubscriptionStartDate!=null){
                tempData = tempData.filter(function(item){
                    let tempInvoiceDate = (item.invoice_created_at!=null)?item.invoice_created_at.split(" ")[0]:null;
                    if(tempInvoiceDate>=tempSubscriptionStartDate){
                        return true;
                    }
                })
            }

            if(tempSubscriptionEndDate!=null){
                tempData = tempData.filter(function(item){
                    let tempInvoiceDate = (item.invoice_created_at!=null)?item.invoice_created_at.split(" ")[0]:null;
                    if(tempInvoiceDate<=tempSubscriptionEndDate){
                        return true;
                    }
                })
            }

            if(tempLastLoginStartDate!=null){
                tempData = tempData.filter(function(item){
                    let tempLastLogin = (item.last_login!=null)?item.last_login.split(" ")[0]:null;
                    if(tempLastLogin>=tempLastLoginStartDate){
                        return true;
                    }
                })
            }

            if(tempLastLoginEndDate!=null){
                tempData = tempData.filter(function(item){
                    let tempLastLogin = (item.last_login!=null)?item.last_login.split(" ")[0]:null;
                    if(tempLastLogin<=tempLastLoginEndDate){
                        return true;
                    }
                })
            }

            this.filteredData = tempData;
            this.downloadableData = this.filteredData.map(obj=>({"Client":obj.user_name,"Email":obj.email,"mobile":obj.mobile,"Company":obj.company_name,"Subscription Date":obj.invoice_created_at,"Last Login":obj.last_login}));
        },
    
        filterUserBasedOnYearMonth(){
            if(this.filteredYearData===null && this.filteredMonthData===null && this.filteredVerificationData===null){
                this.filteredData = this.data;
                return 1;
            }

            let tempDataVerification = this.$_.clone(this.data);
            let tempDataVerificationFiltered = null;
            let tempFilterVerificationSelected = this.$_.clone(this.filteredVerificationData);

        
            if(tempFilterVerificationSelected!=null){
                tempDataVerificationFiltered = tempDataVerification.filter(function(item){
                    return item.verification=== tempFilterVerificationSelected;
                })
    
                this.filteredData = tempDataVerificationFiltered;

            }


            let tempData = this.$_.clone((tempDataVerificationFiltered===null)?this.data:this.filteredData)
            let tempDataYearFiltered=null;
            let tempFilterYearSelected = this.$_.clone(this.filteredYearData);
            if(tempFilterYearSelected!=null){

                tempDataYearFiltered= tempData.filter(function(item){
                    return item.last_login_year===tempFilterYearSelected;
                })
                
                this.filteredData = tempDataYearFiltered;
            }


            let tempYearData = this.$_.clone((tempDataYearFiltered===null)?this.data:this.filteredData)
            let tempDataMonthFiltered=null;
            let tempFilterMonthSelected = this.$_.clone(this.filteredMonthData.value);


            if(tempFilterMonthSelected!=null){
                tempDataMonthFiltered = tempYearData.filter(function(item){
                    return item.last_login_month===tempFilterMonthSelected;
                })

                this.filteredData = tempDataMonthFiltered;
            }

            this.downloadableData = this.filteredData.map(obj=>({
                "Client":obj.user_name,
                "Email":obj.email,
                "mobile":obj.mobile,
                "Company":obj.company_name,
                "Subscription Date":this.convertTimeZone(obj.invoice_created_at),
                "Last Login":this.convertTimeZone(obj.last_login)}));
          


        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        },
        resetFilter(){
            this.filteredData = this.$_.clone(this.data);
            this.lastLoginStartDate = null;
            this.lastLoginEndDate = null;
            this.subscriptionStartDate = null;
            this.subscriptionEndDate = null;
            this.searchData = null;
            this.filteredVerificationData = "All";
            this.downloadableData = this.filteredData.map(obj=>({"Client":obj.user_name,"Email":obj.email,"mobile":obj.mobile,"Company":obj.company_name,"Subscription Date":obj.invoice_created_at,"Last Login":obj.last_login}));
        }
    }
}
</script>